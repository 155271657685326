body {
  background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-9));

  font-variant-numeric: tabular-nums;
}

@media print {
  body {
    background-color: var(--mantine-color-white);
  }
}

@page {
  margin: 5mm;
}

a {
  color: inherit;
  text-decoration: none;
}

pre {
  margin: 0;
}

@media (max-width: $mantine-breakpoint-xs) {
  input {
    font-size: 16px !important;
  }
}

table:not([data-variant="vertical"]) > {
  thead > * {
    background-color: var(--mantine-color-default);
  }

  tfoot {
    border-top: rem(1px) solid var(--table-border-color);

    & > * {
      background-color: var(--mantine-color-default);
    }
  }

  tbody > * {
    border-bottom: none;
    background-color: var(--mantine-color-default);

    &:hover:where([data-hover]) {
      background-color: var(--tr-hover-bg) !important;
    }

    &:where([data-with-row-border]) {
      border-bottom: rem(1px) solid var(--table-border-color);
    }

    &:where([data-with-row-border]:last-of-type) {
      border-bottom: none;
    }

    &:where([data-striped="odd"]:nth-of-type(odd)) {
      background-color: var(--table-striped-color);
    }

    &:where([data-striped="even"]:nth-of-type(even)) {
      background-color: var(--table-striped-color);
    }

    &[data-hover] {
      --tr-hover-bg: var(--table-highlight-on-hover-color, var(--table-hover-color));
    }
  }
}
