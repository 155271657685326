.m_468e7eda {
  padding-top: 0;
  padding-bottom: 0;
  appearance: none;
}

  .m_468e7eda::-webkit-calendar-picker-indicator {
    display: none;
  }

  .m_468e7eda::-webkit-clear-button {
    display: none;
  }

  .m_468e7eda::-webkit-datetime-edit-hour-field,
  .m_468e7eda::-webkit-datetime-edit-minute-field,
  .m_468e7eda::-webkit-datetime-edit-second-field,
  .m_468e7eda::-webkit-datetime-edit-ampm-field {
    padding-top: 0;
    max-height: calc(1.875rem * var(--mantine-scale));
    display: inline;
  }

  .m_468e7eda::-webkit-datetime-edit-hour-field:focus, .m_468e7eda::-webkit-datetime-edit-minute-field:focus, .m_468e7eda::-webkit-datetime-edit-second-field:focus, .m_468e7eda::-webkit-datetime-edit-ampm-field:focus {
      background-color: var(--mantine-primary-color-filled);
      color: var(--mantine-color-white);
    }

.m_396ce5cb {
  --day-size-xs: calc(1.875rem * var(--mantine-scale));
  --day-size-sm: calc(2.25rem * var(--mantine-scale));
  --day-size-md: calc(2.625rem * var(--mantine-scale));
  --day-size-lg: calc(3rem * var(--mantine-scale));
  --day-size-xl: calc(3.375rem * var(--mantine-scale));
  --day-size: var(--day-size-sm);

  width: var(--day-size, var(--day-size-sm));
  height: var(--day-size, var(--day-size-sm));
  font-size: calc(var(--day-size) / 2.8);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  background-color: transparent;
  border-radius: var(--mantine-radius-default);
  color: var(--mantine-color-text);
  opacity: 1;
}

  @media (hover: hover) {
      [data-mantine-color-scheme='light'] .m_396ce5cb:hover:where(:not([data-static], [data-disabled], [data-selected], [data-in-range])) {
        background-color: var(--mantine-color-gray-0);
  }

      [data-mantine-color-scheme='dark'] .m_396ce5cb:hover:where(:not([data-static], [data-disabled], [data-selected], [data-in-range])) {
        background-color: var(--mantine-color-dark-5);
  }
}

  @media (hover: none) {
      [data-mantine-color-scheme='light'] .m_396ce5cb:active:where(:not([data-static], [data-disabled], [data-selected], [data-in-range])) {
        background-color: var(--mantine-color-gray-0);
  }

      [data-mantine-color-scheme='dark'] .m_396ce5cb:active:where(:not([data-static], [data-disabled], [data-selected], [data-in-range])) {
        background-color: var(--mantine-color-dark-5);
  }
}

  .m_396ce5cb:where([data-static]) {
    user-select: auto;
    cursor: default;
  }

  .m_396ce5cb:where([data-weekend]) {
    color: var(--mantine-color-red-6);
  }

  .m_396ce5cb:where([data-outside]) {
    color: var(--mantine-color-dimmed);
    opacity: 0.5;
  }

  .m_396ce5cb:where(:disabled, [data-disabled]) {
    color: var(--mantine-color-dimmed);
    cursor: not-allowed;
    opacity: 0.5;
  }

  .m_396ce5cb:where([data-hidden]) {
    display: none;
  }

  :where([data-mantine-color-scheme='light']) .m_396ce5cb:where([data-today][data-highlight-today]:not([data-selected], [data-in-range])) {
      border: 1px solid var(--mantine-color-gray-4);
}

  :where([data-mantine-color-scheme='dark']) .m_396ce5cb:where([data-today][data-highlight-today]:not([data-selected], [data-in-range])) {
      border: 1px solid var(--mantine-color-dark-4);
}

  .m_396ce5cb:where([data-in-range]) {
    background-color: var(--mantine-primary-color-light-hover);
    border-radius: 0;
  }

  @media (hover: hover) {
      .m_396ce5cb:where([data-in-range]):hover:where(:not([data-disabled], [data-static])) {
        background-color: var(--mantine-primary-color-light);
      }
}

  @media (hover: none) {
      .m_396ce5cb:where([data-in-range]):active:where(:not([data-disabled], [data-static])) {
        background-color: var(--mantine-primary-color-light);
      }
}

  .m_396ce5cb:where([data-first-in-range]) {
    border-radius: 0;
    border-start-start-radius: var(--mantine-radius-default);
    border-end-start-radius: var(--mantine-radius-default);
  }

  .m_396ce5cb:where([data-last-in-range]) {
    border-radius: 0;
    border-end-end-radius: var(--mantine-radius-default);
    border-start-end-radius: var(--mantine-radius-default);
  }

  .m_396ce5cb:where([data-last-in-range][data-first-in-range]) {
    border-radius: var(--mantine-radius-default);
  }

  .m_396ce5cb:where([data-selected]) {
    background-color: var(--mantine-primary-color-filled);
    color: var(--mantine-primary-color-contrast);
  }

  @media (hover: hover) {
      .m_396ce5cb:where([data-selected]):hover:where(:not([data-disabled], [data-static])) {
        background-color: var(--mantine-primary-color-filled-hover);
      }
}

  @media (hover: none) {
      .m_396ce5cb:where([data-selected]):active:where(:not([data-disabled], [data-static])) {
        background-color: var(--mantine-primary-color-filled-hover);
      }
}

.m_18a3eca {
  color: var(--mantine-color-dimmed);
  font-weight: normal;
  font-size: var(--wr-fz, var(--mantine-font-size-sm));
  text-transform: capitalize;
  padding-bottom: calc(var(--wr-spacing, var(--mantine-spacing-sm)) / 2);
}

.m_cc9820d3 {
  border-collapse: collapse;
  table-layout: fixed;
}

.m_8f457cd5 {
  padding: 0;
}

.m_8f457cd5:where([data-with-spacing]) {
    padding: calc(0.03125rem * var(--mantine-scale));
  }

.m_6cff9dea {
  --wn-size-xs: calc(1.875rem * var(--mantine-scale));
  --wn-size-sm: calc(2.25rem * var(--mantine-scale));
  --wn-size-md: calc(2.625rem * var(--mantine-scale));
  --wn-size-lg: calc(3rem * var(--mantine-scale));
  --wn-size-xl: calc(3.375rem * var(--mantine-scale));

  color: var(--mantine-color-dimmed);
  font-weight: normal;
  font-size: calc(var(--wn-size, var(--wn-size-sm)) / 2.8);
  text-align: center;
  width: var(--wn-size, var(--wn-size-sm));
}

.m_dc6a3c71 {
  --dpc-size-xs: calc(1.875rem * var(--mantine-scale));
  --dpc-size-sm: calc(2.25rem * var(--mantine-scale));
  --dpc-size-md: calc(2.625rem * var(--mantine-scale));
  --dpc-size-lg: calc(3rem * var(--mantine-scale));
  --dpc-size-xl: calc(3.375rem * var(--mantine-scale));
  --dpc-size: var(--dpc-size-sm);

  font-size: var(--dpc-fz, var(--mantine-font-size-sm));
  height: var(--dpc-size);
  width: calc((var(--dpc-size) * 7) / 3 + calc(0.09375rem * var(--mantine-scale)));
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  background-color: transparent;
  color: var(--mantine-color-text);
  opacity: 1;
  border-radius: var(--mantine-radius-default);
}

  @media (hover: hover) {
      :where([data-mantine-color-scheme='light']) .m_dc6a3c71:hover:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='dark']) .m_dc6a3c71:hover:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-dark-5);
  }
}

  @media (hover: none) {
      :where([data-mantine-color-scheme='light']) .m_dc6a3c71:active:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='dark']) .m_dc6a3c71:active:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-dark-5);
  }
}

  .m_dc6a3c71:where(:disabled, [data-disabled]) {
    color: var(--mantine-color-dimmed);
    cursor: not-allowed;
    opacity: 0.5;
  }

  .m_dc6a3c71:where([data-selected]) {
    background-color: var(--mantine-primary-color-filled);
    color: var(--mantine-primary-color-contrast, var(--mantine-color-white));
  }

  @media (hover: hover) {

  .m_dc6a3c71:where([data-selected]):hover {
      background-color: var(--mantine-primary-color-filled-hover);
  }
}

  @media (hover: none) {

  .m_dc6a3c71:where([data-selected]):active {
      background-color: var(--mantine-primary-color-filled-hover);
  }
}

  .m_dc6a3c71:where([data-in-range]) {
    background-color: var(--mantine-primary-color-light-hover);
    border-radius: 0;
  }

  @media (hover: hover) {

  .m_dc6a3c71:where([data-in-range]):hover {
      background-color: var(--mantine-primary-color-light);
  }
}

  @media (hover: none) {

  .m_dc6a3c71:where([data-in-range]):active {
      background-color: var(--mantine-primary-color-light);
  }
}

  .m_dc6a3c71:where([data-first-in-range]) {
    border-radius: 0;
    border-start-start-radius: var(--mantine-radius-default);
    border-end-start-radius: var(--mantine-radius-default);
  }

  .m_dc6a3c71:where([data-last-in-range]) {
    border-radius: 0;
    border-end-end-radius: var(--mantine-radius-default);
    border-start-end-radius: var(--mantine-radius-default);
  }

  .m_dc6a3c71:where([data-first-in-range][data-last-in-range]) {
    border-radius: var(--mantine-radius-default);
  }

.m_9206547b {
  border-collapse: collapse;
  border-width: 0;
}

.m_c5a19c7d {
  padding: 0;
}

.m_c5a19c7d:where([data-with-spacing]) {
    padding: calc(0.03125rem * var(--mantine-scale));
  }

.m_2a6c32d {
  border-collapse: collapse;
  border-width: 0;
  cursor: pointer;
}

.m_fe27622f {
  padding: 0;
}

.m_fe27622f:where([data-with-spacing]) {
    padding: calc(0.03125rem * var(--mantine-scale));
  }

.m_730a79ed {
  --dch-control-size-xs: calc(1.875rem * var(--mantine-scale));
  --dch-control-size-sm: calc(2.25rem * var(--mantine-scale));
  --dch-control-size-md: calc(2.625rem * var(--mantine-scale));
  --dch-control-size-lg: calc(3rem * var(--mantine-scale));
  --dch-control-size-xl: calc(3.375rem * var(--mantine-scale));
  --dch-control-size: var(--dch-control-size-sm);

  display: flex;
  max-width: calc(var(--dch-control-size) * 8 + calc(0.4375rem * var(--mantine-scale)));
  margin-bottom: var(--mantine-spacing-xs);
}

.m_f6645d97,
.m_2351eeb0 {
  height: var(--dch-control-size);
  border-radius: var(--mantine-radius-default);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  opacity: 1;
  cursor: pointer;
}

@media (hover: hover) {
      [data-mantine-color-scheme='light'] .m_f6645d97:hover:where(:not([data-disabled], :disabled)), [data-mantine-color-scheme='light'] .m_2351eeb0:hover:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-gray-0);
  }

      [data-mantine-color-scheme='dark'] .m_f6645d97:hover:where(:not([data-disabled], :disabled)), [data-mantine-color-scheme='dark'] .m_2351eeb0:hover:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-dark-5);
  }
}

@media (hover: none) {
      [data-mantine-color-scheme='light'] .m_f6645d97:active:where(:not([data-disabled], :disabled)), [data-mantine-color-scheme='light'] .m_2351eeb0:active:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-gray-0);
  }

      [data-mantine-color-scheme='dark'] .m_f6645d97:active:where(:not([data-disabled], :disabled)), [data-mantine-color-scheme='dark'] .m_2351eeb0:active:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-dark-5);
  }
}

.m_f6645d97:where(:disabled, [data-disabled]), .m_2351eeb0:where(:disabled, [data-disabled]) {
    opacity: 0.2;
    cursor: not-allowed;
  }

.m_2351eeb0 {
  width: var(--dch-control-size);
}

.m_f6645d97 {
  flex: 1;
  font-size: var(--dch-fz, var(--mantine-font-size-sm));
  font-weight: 500;
  text-transform: capitalize;
}

.m_367dc749 {
  width: 60%;
  height: 60%;
}

.m_367dc749:where([data-direction='next']) {
    transform: rotate(270deg);
  }

:where([dir="rtl"]) .m_367dc749:where([data-direction='next']) {
      transform: rotate(90deg);
}

.m_367dc749:where([data-direction='previous']) {
    transform: rotate(90deg);
  }

:where([dir="rtl"]) .m_367dc749:where([data-direction='previous']) {
      transform: rotate(270deg);
}

.m_30b26e33 {
  display: flex;
  gap: var(--mantine-spacing-md);
}

.m_6fa5e2aa {
  cursor: pointer;
  line-height: unset;
}

  .m_6fa5e2aa:where([data-read-only]) {
    cursor: default;
  }

.m_208d2562 {
  display: flex;
  align-items: stretch;
  margin-top: var(--mantine-spacing-md);
}

.m_62ee059 {
  flex: 1;
  margin-inline-end: var(--mantine-spacing-md);
}

.m_d46a4834 {
  position: relative;
  border: calc(0.0625rem * var(--mantine-scale)) dashed;
  color: var(--mantine-color-text);
  padding: var(--mantine-spacing-md);
  border-radius: var(--dropzone-radius);
  cursor: pointer;
  user-select: none;
  transition:
    background-color 100ms ease,
    border-color 100ms ease;
}

  .m_d46a4834:where([data-loading]),
  .m_d46a4834:where(:not([data-activate-on-click])) {
    cursor: default;
  }

  :where([data-mantine-color-scheme='light']) .m_d46a4834 {
    background-color: var(--mantine-color-white);
    border-color: var(--mantine-color-gray-4);
}

  :where([data-mantine-color-scheme='dark']) .m_d46a4834 {
    background-color: var(--mantine-color-dark-6);
    border-color: var(--mantine-color-dark-4);
}

  @media (hover: hover) {
      :where([data-mantine-color-scheme='light']) .m_d46a4834:hover:where([data-activate-on-click]:not([data-loading])) {
        background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='dark']) .m_d46a4834:hover:where([data-activate-on-click]:not([data-loading])) {
        background-color: var(--mantine-color-dark-5);
  }
}

  @media (hover: none) {
      :where([data-mantine-color-scheme='light']) .m_d46a4834:active:where([data-activate-on-click]:not([data-loading])) {
        background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='dark']) .m_d46a4834:active:where([data-activate-on-click]:not([data-loading])) {
        background-color: var(--mantine-color-dark-5);
  }
}

  .m_d46a4834:where([data-accept]) {
    background-color: var(--dropzone-accept-bg);
    border-color: var(--dropzone-accept-bg);
    color: var(--dropzone-accept-color);
  }

  .m_d46a4834:where([data-reject]) {
    background-color: var(--dropzone-reject-bg);
    border-color: var(--dropzone-reject-bg);
    color: var(--dropzone-reject-color);
  }

.m_b85f7144 {
  pointer-events: none;
  user-select: none;
}

.m_b85f7144:where([data-enable-pointer-events]) {
    pointer-events: all;
  }

.m_96f6e9ad {
  position: fixed;
  inset: 0;
  background-color: var(--mantine-color-body);
  display: flex;
  flex-direction: column;
  padding: var(--mantine-spacing-xs);
  transition: opacity 100ms ease;
}

.m_96f6e9ad .m_7946116d {
    flex: 1;
  }

.m_b37d9ac7 {
  width: calc(100% - var(--mantine-spacing-md) * 2);
  position: fixed;
  z-index: var(--notifications-z-index);
  max-width: var(--notifications-container-width);
}

  .m_b37d9ac7:where([data-position='top-center']) {
    top: var(--mantine-spacing-md);
    left: 50%;
    transform: translateX(-50%);
  }

  .m_b37d9ac7:where([data-position='top-left']) {
    top: var(--mantine-spacing-md);
    left: var(--mantine-spacing-md);
  }

  .m_b37d9ac7:where([data-position='top-right']) {
    top: var(--mantine-spacing-md);
    right: var(--mantine-spacing-md);
  }

  .m_b37d9ac7:where([data-position='bottom-center']) {
    bottom: var(--mantine-spacing-md);
    left: 50%;
    transform: translateX(-50%);
  }

  .m_b37d9ac7:where([data-position='bottom-left']) {
    bottom: var(--mantine-spacing-md);
    left: var(--mantine-spacing-md);
  }

  .m_b37d9ac7:where([data-position='bottom-right']) {
    bottom: var(--mantine-spacing-md);
    right: var(--mantine-spacing-md);
  }

.m_5ed0edd0 + .m_5ed0edd0 {
    margin-top: var(--mantine-spacing-md);
  }

.m_8f2832ae {
  position: fixed;
  top: 0;
  inset-inline: 0;
  z-index: var(--nprogress-z-index);
  background-color: transparent;
  transition: opacity 150ms ease;
  transition-delay: 50ms;
  opacity: 0;
  overflow: visible;
}

  .m_8f2832ae:where([data-mounted]) {
    opacity: 1;
  }

.m_7a0fe999 {
  position: relative;
  transition: width 150ms ease;
  overflow: visible;
}

.m_7a0fe999::before {
    content: '';
    position: absolute;
    width: calc(6.25rem * var(--mantine-scale));
    height: var(--progress-size);
    top: 0;
    inset-inline-end: calc(0.0625rem * var(--mantine-scale));
    transform: rotate(4deg) translateY(-4px);
    box-shadow:
      0 0 10px var(--progress-section-color),
      0 0 5px var(--progress-section-color);
  }

.m_d2b315db[data-scrollable] {
    --spotlight-content-height: 100%;
  }

.m_3cd250e0 {
  overflow: hidden;
  height: var(--spotlight-content-height, auto);
  max-height: var(--spotlight-max-height);
}

.m_d2abce9b {
  display: flex;
  flex-direction: column;
  height: var(--spotlight-content-height, auto);
}

.m_f366a061 {
  border: 0;
  background-color: transparent;
}

.m_6e463822 {
  /* Used in child ScrollArea */
  --spotlight-actions-list-padding: calc(0.25rem * var(--mantine-scale));

  border-top: calc(0.0625rem * var(--mantine-scale)) solid;
  flex: 1;
  padding: var(--spotlight-actions-list-padding);
  max-height: calc(100% - 3.125rem);
  margin-inline-end: calc(var(--spotlight-actions-list-padding) * -1);
}

:where([data-mantine-color-scheme='light']) .m_6e463822 {
    border-color: var(--mantine-color-gray-2);
}

:where([data-mantine-color-scheme='dark']) .m_6e463822 {
    border-color: var(--mantine-color-dark-4);
}

.m_d49bb8ef {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 7px var(--mantine-spacing-md);
  border-radius: var(--mantine-radius-default);
  background-color: transparent;
  color: inherit;
  text-align: left;
}

:where([dir="rtl"]) .m_d49bb8ef {
    text-align: right;
}

@media (hover: hover) {
      :where([data-mantine-color-scheme='light']) .m_d49bb8ef:where(:not([data-selected])):hover {
        background-color: var(--mantine-color-gray-0);
    }

      :where([data-mantine-color-scheme='dark']) .m_d49bb8ef:where(:not([data-selected])):hover {
        background-color: var(--mantine-color-dark-6);
    }
}

@media (hover: none) {
      :where([data-mantine-color-scheme='light']) .m_d49bb8ef:where(:not([data-selected])):active {
        background-color: var(--mantine-color-gray-0);
    }

      :where([data-mantine-color-scheme='dark']) .m_d49bb8ef:where(:not([data-selected])):active {
        background-color: var(--mantine-color-dark-6);
    }
}

.m_d49bb8ef:where([data-selected]) {
    background-color: var(--mantine-primary-color-filled);
    color: var(--mantine-primary-color-contrast, var(--mantine-color-white));
    --action-description-color: var(--mantine-primary-color-contrast, var(--mantine-color-white));
    --action-description-opacity: 0.7;
  }

.m_3d475731 {
  flex: 1;
}

.m_832642f6 {
  opacity: 1;
  color: inherit;
}

.m_832642f6:where([data-dimmed]) {
    opacity: var(--action-description-opacity, 1);
    color: var(--action-description-color, var(--mantine-color-dimmed));
  }

.m_832642f6:where([data-position='left']) {
    margin-inline-end: var(--mantine-spacing-md);
  }

.m_832642f6:where([data-position='right']) {
    margin-inline-start: var(--mantine-spacing-md);
  }

.m_832642f6 > svg {
    display: block;
  }

.m_6c2a1345 {
  display: block;
}

.m_a6d9d78d {
  display: block;
  font-size: var(--mantine-font-size-xs);
  opacity: var(--action-description-opacity, 1);
  color: var(--action-description-color, var(--mantine-color-dimmed));
}

.m_82f78f74 {
  color: var(--mantine-color-dimmed);
  padding: var(--mantine-spacing-md);
  text-align: center;
}

.m_ddcaf054 {
  padding: var(--mantine-spacing-xs) calc(var(--mantine-spacing-md) + calc(0.25rem * var(--mantine-scale)));
  border-top: calc(0.0625rem * var(--mantine-scale)) solid;
}

:where([data-mantine-color-scheme='light']) .m_ddcaf054 {
    border-color: var(--mantine-color-gray-2);
}

:where([data-mantine-color-scheme='dark']) .m_ddcaf054 {
    border-color: var(--mantine-color-dark-4);
}

.m_5a3e5f7b {
  display: flex;
  flex-direction: column;
  margin-top: var(--mantine-spacing-md);
}

.m_5a3e5f7b::before {
    content: var(--spotlight-label);
    text-transform: uppercase;
    font-size: var(--mantine-font-size-xs);
    font-weight: 700;
    color: var(--mantine-color-dimmed);
    padding-inline-start: var(--mantine-spacing-md);
  }

.m_5a3e5f7b:empty {
    display: none;
  }

@media (max-width: 62em) {.theme_modalInner__lQmvR {
    top: unset !important;
    padding: 0 !important
}
  }

@media (max-width: 62em) {

.theme_modalContent__36Zi5 {
    height: 85dvh !important;
    flex-basis: 100% !important
}
  }

body {
  background-color: var(--mantine-color-gray-0);
}
  [data-mantine-color-scheme='dark'] body {
  background-color: var(--mantine-color-dark-9);
}
  body {

  font-variant-numeric: tabular-nums;
}

@media print {
  body {
    background-color: var(--mantine-color-white);
  }
}

@page {
  margin: 5mm;
}

a {
  color: inherit;
  text-decoration: none;
}

pre {
  margin: 0;
}

@media (max-width: 36em) {
  input {
    font-size: 16px !important;
  }
}

table:not([data-variant="vertical"]) > thead > * {
    background-color: var(--mantine-color-default);
  }

table:not([data-variant="vertical"]) > tfoot {
    border-top: calc(0.0625rem * var(--mantine-scale)) solid var(--table-border-color);
  }

table:not([data-variant="vertical"]) > tfoot > * {
      background-color: var(--mantine-color-default);
    }

table:not([data-variant="vertical"]) > tbody > * {
    border-bottom: none;
    background-color: var(--mantine-color-default);
  }

table:not([data-variant="vertical"]) > tbody > *:hover:where([data-hover]) {
      background-color: var(--tr-hover-bg) !important;
    }

table:not([data-variant="vertical"]) > tbody > *:where([data-with-row-border]) {
      border-bottom: calc(0.0625rem * var(--mantine-scale)) solid var(--table-border-color);
    }

table:not([data-variant="vertical"]) > tbody > *:where([data-with-row-border]:last-of-type) {
      border-bottom: none;
    }

table:not([data-variant="vertical"]) > tbody > *:where([data-striped="odd"]:nth-of-type(odd)) {
      background-color: var(--table-striped-color);
    }

table:not([data-variant="vertical"]) > tbody > *:where([data-striped="even"]:nth-of-type(even)) {
      background-color: var(--table-striped-color);
    }

table:not([data-variant="vertical"]) > tbody > *[data-hover] {
      --tr-hover-bg: var(--table-highlight-on-hover-color, var(--table-hover-color));
    }

