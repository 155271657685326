.modalInner {
  @media (max-width: $mantine-breakpoint-md) {
    top: unset !important;
    padding: 0 !important;
  }
}

.modalContent {
  @media (max-width: $mantine-breakpoint-md) {
    height: 85dvh !important;
    flex-basis: 100% !important;
  }
}
